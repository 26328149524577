<template>
    <div style="display: flex">
        <!-- <div>
            <Tabs value="name1" type="card" style="display: flex;flex-direction: column;">
                <TabPane label="企微通知" name="name1"></TabPane>
                <TabPane label="订阅通知" name="name2"></TabPane>
            </Tabs>
        </div> -->
        <Menu mode="vertical" :theme="theme" :active-name="act" @on-select="selctChange">
            <MenuItem name="1">
                <Icon type="ios-paper" />
                企微订阅通知
            </MenuItem>
            <!-- <MenuItem name="2">
                <Icon type="ios-people" />
                公众号订阅通知
            </MenuItem> -->
            <!-- <Submenu name="3" disabled>
                <template #title>
                    <Icon type="ios-stats" />
                    公众号订阅通知
                </template>
                <MenuGroup title="暂无">
                    
                </MenuGroup>
                
            </Submenu> -->
            <MenuItem name="2">
                <Icon type="ios-construct" />
                小程序订阅通知
            </MenuItem>
            <MenuItem name="3">
                <Icon type="ios-stats" />
                公众号订阅通知
            </MenuItem>
        </Menu>
        <searchBody>
            <Card style="width: 70vw">
                <div slot="title">{{ title }}</div>
                <!-- <Card v-if="!isEdit" title="账号信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">账号昵称</div>
                <Input class="width_300" v-model.trim="formData.nickName" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">账号</div>
                <Input class="width_300" v-model.trim="formData.userPhone" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">密码</div>
                <Input class="width_300" type="password" v-model.trim="formData.passWord" password></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">角色选择</div>
                <Select class="width_300" v-model="formData.groupId">
                    <Option v-for="info in roleList" :key="info.id" :value="info.id">{{ info.groupname }}</Option>
                </Select>
            </div>
        </Card> -->
                <Button class="ml_100" type="primary" :loading="ajaxLoading" style="position: absolute; right: 15px; top: 5px" @click="addTemplate">新增模板</Button>
                <Card :title="item.title" dis-hover v-for="item in qwlist" :key="item.id" style="margin-top: 15px; width: 100%">
                    <div style="display: flex; position: absolute; top: 15px; right: 30px">
                        <div class="btn" @click="toEdit(item.id)">编辑</div>
                        <div class="btn" @click="toCopy(item)">复制</div>
                        <div class="btn" @click="delItem(item.id)">删除</div>
                    </div>
                    <!-- <div style="width: 100%; height: 40px; padding: 10px; background-color: rgb(246, 246, 246); line-height: 40px; display: flex; align-items: center">{{ item.title }}</div> -->
                    <div v-if="actShow == '1'" style="width: 100%; height: 80px; padding: 10px; background-color: rgb(246, 246, 246); display: flex; margin-top: 15px">{{ item.content }}</div>
                    <div v-else-if="actShow == '2'" style="width: 100%; height: 100%; padding: 10px; flex-direction: column; background-color: rgb(246, 246, 246); display: flex; margin-top: 15px">
                        <div v-for="(item7, index) in item.contentArr" :key="index">
                            <span>{{ item7.label }}:</span>
                            <span>{{ item7.value }}</span>
                        </div>
                    </div>
                    <div v-else-if="actShow == '3'" style="width: 100%; height: 100%; padding: 10px; flex-direction: column; background-color: rgb(246, 246, 246); display: flex; margin-top: 15px">
                        <div v-for="(item7, index) in item.contentArr1" :key="index">
                            <span>{{ item7.label }}:</span>
                            <span>{{ item7.value }}</span>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 12px;margin-left: -90px;">
                        <div class="form_item" v-if="actShow == '1'">
                            <div class="form_label">发送节点</div>
                            <div v-if="item.type == '1'">发送报告时</div>
                            <div v-if="item.type == '2'">提交预约时</div>
                        </div>
                        <div class="form_item" v-if="actShow == '2'">
                            <div class="form_label">发送节点</div>
                            <div v-if="item.trigger_type == '1'">发送报告时</div>
                        </div>
                        
                        <!-- <div class="form_item">
                        <div class="form_label">发送方式</div>
                        <div>测试</div>
                    </div> -->
                        <div class="form_item" v-if="actShow == '1'">
                            <div class="form_label">接收人员</div>
                            <div>{{ item.user_names }}</div>
                        </div>
                        <div class="form_item">
                            <div class="form_label">发送时间</div>
                            <div>{{ item.created_at }}</div>
                        </div>
                        <div class="form_item" style="margin-left: -10px;">
                            <div class="form_label">状态</div>
                            <div v-if="item.status == '1'">开启</div>
                            <div v-if="item.status == '0'">关闭</div>
                        </div>
                    </div>
                </Card>
                <Card title="" dis-hover>
                    <!-- <div class="form_item">
                <div class="form_label">分佣比例</div>
                <div v-if="isDetail == 'true'">{{ formData.dealer_rate }}</div>
                <InputNumber :max="100" v-model="formData.dealer_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <div class="form_item">
                <div class="form_label">分佣范围</div>
                <div style="display: flex">
                    <InputNumber :max="100" v-model="value1" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                    ~
                    <InputNumber :max="100" v-model="value2" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </div>
            </div>
            <div class="form_item">
                <div class="form_label must_input">比例是否可超出</div>
                <RadioGroup v-model="formData.dealer_over_allow" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input">超出审核单位</div>
                <Select v-model="formData.dealer_over_exam" style="width: 200px">
                    <Option v-for="item in ccunitList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="form_item">
                <div class="form_label must_input">经销商数量</div>
                <Select v-model="formData.dealer_limit" style="width: 200px">
                    <Option v-for="item in numList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div> -->
                </Card>
                <!-- <Card title="三级个人\小B端规则设定" dis-hover>
            <div class="form_item">
                <div class="form_label">分佣比例</div>
                <div v-if="isDetail == 'true'">{{ formData.person_rate }}</div>
                <InputNumber :max="100" v-model="formData.person_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <div class="form_item">
                <div class="form_label">分佣范围</div>
                <div style="display: flex">
                    <InputNumber :max="100" v-model="value3" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                    ~
                    <InputNumber :max="100" v-model="value4" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </div>
            </div>
            <div class="form_item">
                <div class="form_label must_input">比例是否可超出</div>
                <RadioGroup v-model="formData.person_over_allow" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input">超出审核单位</div>
                <Select v-model="formData.person_over_exam" style="width: 200px">
                    <Option v-for="item in cczunitList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="form_item">
                <div class="form_label must_input">经销商数量</div>
                <Select v-model="formData.person_limit" style="width: 200px">
                    <Option v-for="item in numList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
        </Card> -->
                <!-- <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">返回</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirm">确定</i-button>
        </div> -->
                <Modal v-model="showdepart" width="800" title="选择人员">
                    <div style="display: flex">
                        <Tree :data="treedata" @on-select-change="selectChange"></Tree>
                        <CheckboxGroup v-model="checkList" style="display: flex; flex-direction: column; margin: 15px 0" @on-change="changeCheck">
                            <Checkbox :label="item.userid" :value="item.userid" v-for="item in userlist" :key="item.userid">{{ item.name }}</Checkbox>
                        </CheckboxGroup>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="showdepart = false">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="suredepart">确定</Button>
                    </div>
                </Modal>
                <Modal v-model="showCreate" :title="titlec" width="1000" :loading="ajaxLoading">
                    <div class="form_item" style="margin-left: -100px">
                        <div class="form_label must_input">模板名称</div>
                        <Input v-model="formData.title" placeholder="请输入"></Input>
                    </div>
                    <div class="form_item" style="margin-left: -100px">
                        <div class="form_label must_input">推送节点</div>
                        <Select v-model="formData.type" style="width: 600px">
                            <Option v-for="(item, index) in cczunitList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="form_item" style="margin-left: -100px">
                        <div class="form_label must_input">推送内容</div>
                        <!-- <Input v-model="formData.content" @on-focus="pushZwf" ref="myTextarea"
       type="textarea"></Input> -->
                        <textarea v-model="formData.content" placeholder="请输入推送内容" style="width: 100%; padding: 5px" placeholder-class="stc" ref="myTextarea" @click="handleClick"></textarea>
                    </div>
                    <Button size="small" type="info"  style="margin-left: 115px;font-size: 14px;"   @click="insertAtCaret('{orderSn}')">检测单号</Button>
                    <div style="width: 100%; height: 80px; padding: 10px; background-color: rgb(246, 246, 246); display: flex; margin-top: 15px">示例；用户下单预约成功，请及时联系用户..</div>
                    <!-- <div class="form_item" style="margin-left: -118px">
                    <div class="form_label must_input">发送方式</div>
                    <Select v-model="formData.person_over_exam" style="width: 600px">
                        <Option v-for="item in cczunitList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div> -->
                    <div class="form_item" style="margin-left: -100px">
                        <div class="form_label must_input">接收人员</div>
                        <div>{{ formData.user_names }}</div>
                        <Button type="primary" :loading="ajaxLoading" @click="goSelect">去选择</Button>
                    </div>
                    <div class="form_item" style="margin-left: -100px">
                        <div class="form_label must_input">状态</div>
                        <RadioGroup v-model="formData.status" type="button" button-style="solid">
                            <Radio label="1">开启</Radio>
                            <Radio label="0">关闭</Radio>
                        </RadioGroup>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
                    </div>
                </Modal>
                <Modal v-model="showCreate1" :title="titlec" width="1000" :loading="ajaxLoading">
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">模板名称</div>
                        <Input v-model="formData.title" placeholder="请输入"></Input>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">模板选择</div>
                        <Select v-model="formData.template_id" style="width: 600px" @on-change="changeTp">
                            <Option v-for="(item, index) in mpList" :value="item.priTmplId" :key="index">{{ item.title }}</Option>
                        </Select>
                    </div>
                    <div v-for="(item, index) in structList" :key="index" style="margin-top: 8px">
                        <!-- <div style="margin-left: 20px;margin-top: 5px;">{{ item.title }}</div> -->
                        <div class="form_item" style="margin-left: -95px">
                            <div class="form_label must_input" >{{ item.title }}</div>
                            <!-- <textarea v-model="cData[item.field]" placeholder="请输入" style="width: 100%; padding: 5px" placeholder-class="stc" ref="myTextareat" @click="handleClick1"></textarea> -->
                            <textarea v-model="cData[item.field]" placeholder="请输入" style="width: 100%; padding: 5px" placeholder-class="stc" :ref="`myTextareat${index}`" @click="handleClick1(index)"></textarea>
                        </div>
                        <Button size="small" type="info"  style="margin-left: 115px;font-size: 14px;"  @click="insertAtCaret1('{orderSn}', item.field)">检测单号</Button>
                        <!-- <button @click="insertAtCaret1('{orderSn}', item.field)" style="width: 70px; font-size: 14px; color: #fff; background-color: #2d8cf0; border-color: #2d8cf0; padding: 5px; margin-left: 90px">检测单号</button> -->
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">推送节点</div>
                        <Select v-model="formData.trigger_type" style="width: 600px">
                            <Option v-for="(item, index) in cczunitList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">订阅类型</div>
                        <Select v-model="formData.sub_type" style="width: 600px">
                            <Option v-for="(item, index) in cctzyunitList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">状态</div>
                        <RadioGroup v-model="formData.status" type="button" button-style="solid">
                            <Radio label="1">开启</Radio>
                            <Radio label="0">关闭</Radio>
                        </RadioGroup>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="onCancelCreate1">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate1">确定</Button>
                    </div>
                </Modal>
                <Modal v-model="showCreate2" :title="titlec" width="1000" :loading="ajaxLoading">
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">模板名称</div>
                        <Input v-model="formData.title" placeholder="请输入"></Input>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">模板选择</div>
                        <Select v-model="formData.template_id" style="width: 600px" @on-change="changeTp1">
                            <Option v-for="(item, index) in pnList" :value="item.template_id" :key="index">{{ item.title }}</Option>
                        </Select>
                    </div>
                    <div v-for="(item, index) in structList1" :key="index" style="margin-top: 8px">
                        <!-- <div style="margin-left: 20px;margin-top: 5px;">{{ item.title }}</div> -->
                        <div class="form_item" style="margin-left: -95px">
                            <div class="form_label must_input" >{{ item.title }}</div>
                            <!-- <textarea v-model="cData[item.field]" placeholder="请输入" style="width: 100%; padding: 5px" placeholder-class="stc" ref="myTextareat" @click="handleClick1"></textarea> -->
                            <textarea v-model="cData[item.field]" placeholder="请输入" style="width: 100%; padding: 5px" placeholder-class="stc" :ref="`myTextareat${index}`" @click="handleClick1(index)"></textarea>
                        </div>
                        <Button size="small" type="info"  style="margin-left: 115px;font-size: 14px;"  @click="insertAtCaret1('{orderSn}', item.field)">检测单号</Button>
                        <Button size="small" type="info"  style="margin-left: 20px;font-size: 14px;"  @click="insertAtCaret1('{nickname}', item.field)">名称</Button>
                        
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">推送节点</div>
                        <Select v-model="formData.trigger_type" style="width: 600px">
                            <Option v-for="(item, index) in cctzyunitListth" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">发送人员</div>
                        <Select v-model="formData.crowd_type" style="width: 600px">
                            <Option v-for="(item, index) in crowdTypetList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">模板链接</div>
                        <Input v-model="formData.url" placeholder="请输入"></Input>
                    </div>
                    <div class="form_item" style="margin-left: -95px">
                        <div class="form_label must_input">状态</div>
                        <RadioGroup v-model="formData.status" type="button" button-style="solid">
                            <Radio label="1">开启</Radio>
                            <Radio label="0">关闭</Radio>
                        </RadioGroup>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="onCancelCreate2">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate2">确定</Button>
                    </div>
                </Modal>
                <Modal v-model="delmodal" title="删除" :loading="loading" @on-ok="asyncOK">
                    <p>您是否确认删除此项？</p>
                </Modal>
                <mySpin :loading="ajaxLoading"></mySpin>
            </Card>
        </searchBody>
    </div>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import {
    reqGroupList,
    addAgent,
    updateAgent,
    readAgent,
    saveRule,
    massList,
    massSave,
    getSubtplList,
    tplCropSave,
    getDepartmentList,
    tplCropRead,
    simpleList,
    tplCropDelete,
    tplMpList,
    mpTplList,
    tplMpSave,
    tplMpReadUrl,
    tplMpRead,
    tplMpDelete,
    pnTplList,
    tplPnList,
    tplPnSave,
    tplPnRead,
    tplPnDelete
} from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';
import UploadImage from '@/components/uploadImage';
import searchBody from '@/components/searchBody';
import { departmentListUrl } from '@/lib/api';
import cloneDeep from 'lodash/cloneDeep';

export default {
    mixins: [pageMixins],
    name: 'systemindex',
    components: {
        city,
        UploadImage,
        searchBody,
    },
    data() {
        return {
            showCreate2:false,
            cData: {},
            showCreate1: false,
            act: '1',
            actShow: '1',
            titlec: '新增',
            delmodal: false,
            checkList: [],
            showdepart: false,
            treedata: [
                {
                    title: 'parent 1',
                    expand: true,
                    children: [
                        {
                            title: 'parent 1-1',
                            expand: true,
                            children: [
                                {
                                    title: 'leaf 1-1-1',
                                },
                                {
                                    title: 'leaf 1-1-2',
                                },
                            ],
                        },
                        {
                            title: 'parent 1-2',
                            expand: true,
                            children: [
                                {
                                    title: 'leaf 1-2-1',
                                },
                                {
                                    title: 'leaf 1-2-1',
                                },
                            ],
                        },
                    ],
                },
            ],
            theme: 'light',
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            ccunitList: [
                {
                    value: '总部',
                    label: '总部',
                },
                {
                    value: '省代理',
                    label: '省代理',
                },
            ],
            crowdTypetList:[
                {
                    value: '1',
                    label: '检测人员',
                },
                {
                    value: '2',
                    label: '服务商',
                },
            ],
            cczunitList: [
                {
                    value: '1',
                    label: '发送报告时',
                },
                {
                    value: '2',
                    label: '提交预约时',
                },
            ],
            cctzyunitList: [
                {
                    value: '1',
                    label: '提交预约时',
                },
            ],
            cctzyunitListth: [
                {
                    value: '1',
                    label: '分配检测单',
                },
            ],
            numList: [
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '20',
                    label: '20',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
                {
                    value: '不限制',
                    label: '不限制',
                },
            ],
            model: '',
            showCreate: false,
            ratevalue: 0,
            fruit: '',
            vertical: '',
            title: '添加模板',
            formData: {
                dealer_over_allow: 0,
                person_over_allow: 0
            },
            address_code: [],
            address_note: null,
            roleList: [],
            address: [],
            isEdit: false,
            isDetail: false,
            arrPic: [],
            departmentList: [],
            query: {
                department_id: '',
            },
            userlist: [],
            namelist: [],
            newlist: [],
            qwlist: [],
            delid: '',
            editId: '',
            insertionText: '插入的内容',
            caretPosition: 0,
            caretPosition1: 0,
            mpList: [],
            structList: [],
            structList1:[],
            contentArr: [],
            pnList:[],
            tplPnList:[],
            textAreaindex:0
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.isEdit = this.$route.query.isEdit;
        this.formData.id = this.$route.query.id;
        this.isDetail = this.$route.query.isDetail;
        console.log(this.$route.query.isEdit, 'this.$route.query.isEdit');
        this.title = this.isEdit == 'true' ? '消息管理' : '消息管理';
        // this.getRoleList();
        // this.getDetail();
        // this.getData();
        if (this.actShow == '1') {
            this.getqwList();
        } else if(this.actShow == '2') {
            this.getMpList();
            this.getTepList();
        }else{
            this.getpnTplList();
            this.gettplPnList();
        }
        this.getDepartmentbmList();
    },
    watch: {},
    methods: {
        changeTp(item) {
            this.mpList.forEach(itemName => {
                if (itemName.priTmplId == item) {
                    this.structList = itemName.struct;
                }
            });
            console.log(item, 'ii');
        },
        changeTp1(item) {
            this.pnList.forEach(itemName => {
                if (itemName.template_id == item) {
                    this.structList1 = itemName.struct;
                }
            });
            console.log(item, 'ii');
        },
        getTepList() {
            mpTplList().then(res => {
                this.mpList = res.data;
            });
        },
        getpnTplList(){
            pnTplList().then(res=>{
                this.pnList = res.data;
            })
        },

        gettplPnList(){
            tplPnList().then(res=>{
                this.qwlist = res.data;
                res.data.forEach(item6 => {
                    let struck = JSON.parse(item6.struck);
                    let data = JSON.parse(item6.data);
                    console.log(struck, '+999');
                    console.log(data, '+999111');
                    item6.contentArr1 = [];
                    struck.forEach(item => {
                        for (let k in data) {
                            console.log(k, '999');
                            console.log(item.field, '9991');
                            if (item.field == k) {
                                var obj = {};
                                obj.label = item.title;
                                obj.value = data[k].value;
                                item6.contentArr1.push(obj);
                            }
                        }
                    });
                });
            });
        },
        selctChange(name) {
            console.log(name, '88');
            this.actShow = name;
            if (this.actShow == '1') {
                this.getqwList();
            } else if(this.actShow == '2') {
                this.getMpList();
                this.getTepList();
            }else{
                this.getpnTplList();
                this.gettplPnList();
                console.log('77')
            }
        },
        toCopy(row) {
            if (this.actShow == '1') {
                var obj = row;
                obj.id = 0;
                tplCropSave(obj)
                    .then(res => {
                        this.fadeAlert('复制成功');

                        this.getqwList();
                    })
                    .catch(() => {
                        this.hideAjaxLoading();
                    });
            } else if(this.actShow == '2') {
                var obj1 = row;
                obj1.id = 0;
                tplMpSave(obj1)
                    .then(res => {
                        this.fadeAlert('复制成功');

                        this.getMpList();
                    })
                    .catch(() => {
                        this.hideAjaxLoading();
                    });
            }else{
                var obj2 = row;
                obj2.id = 0;
                tplMpSave(obj2)
                    .then(res => {
                        this.fadeAlert('复制成功');
                        this.gettplPnList();
                    })
                    .catch(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        handleClick(event) {
            this.caretPosition = this.$refs.myTextarea.selectionStart;
            console.log(this.$refs.myTextarea, 'ss this.caretPosition');
        },
        handleClick1(index) {
            console.log(this.cData, '96533');
            this.$nextTick(() => {
                const textareaRef = this.$refs[`myTextareat${index}`];
                console.log(textareaRef, '999');
                this.caretPosition1 = textareaRef[0].selectionStart;
                this.textAreaindex = index;
                console.log(this.caretPosition1, 'ss this.caretPosition2223123');
            });
        },
        insertAtCaret(text) {
            if (this.formData.content) {
                const before = this.formData.content.substring(0, this.caretPosition);
                const after = this.formData.content.substring(this.caretPosition);
                this.formData.content = before + text + after;
                // 更新光标位置
                this.$nextTick(() => {
                    this.$refs.myTextarea.selectionStart = this.$refs.myTextarea.selectionEnd = this.caretPosition + text.length;
                });
            } else {
                this.fadeWarning('请先输入内容');
            }
        },
        insertAtCaret1(text, filed) {
            if (this.cData[filed]) {
                console.log(this.cData[filed], 'this.cData[filed]');
                console.log(this.caretPosition1, '74554');
                const before = this.cData[filed].substring(0, this.caretPosition1);
                console.log(before, 'before');
                const after = this.cData[filed].substring(this.caretPosition1);
                console.log(after, 'after');

                this.cData[filed] = before + text + after;
                // 更新光标位置
                this.$nextTick(() => {
                    console.log(text.length, 'text.length');
                    console.log(this.caretPosition1, 'this.caretPosition11111111111111');
                    this.$refs[`myTextareat${this.textAreaindex}`][0].selectionStart = this.$refs[`myTextareat${this.textAreaindex}`][0].selectionEnd = this.caretPosition1 + text.length;
                });
            } else {
                this.fadeWarning('请先输入内容');
            }
        },
        pushZwf() {
            var arr = this.formData.content.split('');
            console.log(arr, 'arr');

            arr.push('{orderSn}');
            this.formData.content = arr.join('');
            console.log(this.formData.content, '77');
        },
        toAddzwf() {},
        toEdit(id) {
            this.formData = {};
            this.editId = id;
            if (this.actShow == '1') {
                this.showCreate = true;
                this.getqwDetail();
            } else if(this.actShow == '2') {
                this.getTepList();
                this.showCreate1 = true;
                this.gettpDetail();
            }else{
                this.getpnTplList();
                this.showCreate2 = true;
                this.getgzDetail();
            }

            this.titlec = '编辑';
        },
        getqwDetail() {
            tplCropRead({ id: this.editId }).then(res => {
                this.formData = res.data;
            });
        },
        getgzDetail(){ 
            tplPnRead({ id: this.editId }).then(res => {
                this.formData = res.data;
                this.formData.href = JSON.parse(res.data.href);
                this.formData.url = this.formData.href.url;
                let struck = JSON.parse(res.data.struck);
                let data = JSON.parse(res.data.data);
                this.pnList.forEach(itemName => {
                    if (itemName.template_id == res.data.template_id) {
                        console.log('777');
                        this.structList1 = itemName.struct;
                        this.structList1.forEach(itemName1 => {
                            for (let k in data) {
                                if (itemName1.field == k) {
                                    this.cData[itemName1.field] = data[k].value;
                                }
                            }
                        });
                    }
                });
                console.log(this.structList, 'this.structList');
            });
        },
        gettpDetail() {
            tplMpRead({ id: this.editId }).then(res => {
                this.formData = res.data;
                let struck = JSON.parse(res.data.struck);
                let data = JSON.parse(res.data.data);
                this.mpList.forEach(itemName => {
                    if (itemName.priTmplId == res.data.template_id) {
                        console.log('777');
                        this.structList = itemName.struct;
                        this.structList.forEach(itemName1 => {
                            for (let k in data) {
                                if (itemName1.field == k) {
                                    this.cData[itemName1.field] = data[k].value;
                                }
                            }
                        });
                    }
                });
                console.log(this.structList, 'this.structList');
            });
        },
        delItem(id) {
            this.delid = id;
            this.delmodal = true;
        },
        asyncOK() {
            if (this.actShow == '1') {
                tplCropDelete({ id: this.delid }).then(res => {
                    this.fadeAlert('删除成功');
                    this.getqwList();
                    this.delmodal = false;
                });
            } else if(this.actShow == '2') {
                tplMpDelete({ id: this.delid }).then(res => {
                    this.fadeAlert('删除成功');
                    this.getMpList();
                    this.delmodal = false;
                });
            }else{
                tplPnDelete({ id: this.delid }).then(res => {
                    this.fadeAlert('删除成功');
                    this.gettplPnList();
                    this.delmodal = false;
                });
                
            }
        },
        testchangeCheck(val) {
            console.log(val, 'ssaa');
        },
        changeCheck(val) {
            var namelist = val;
            console.log(val, 'val');
            this.namelist = [];
            this.userlist.forEach(item1 => {
                namelist.forEach(item => {
                    if (item == item1.userid) {
                        console.log(item1.name);
                        this.namelist.push(item1.name);
                        //    item = item1.name
                    }
                });
            });
            var newlist = [...new Set(this.namelist)];
            this.newlist = newlist;
        },
        suredepart() {
            this.formData.user_names = this.newlist.join('|');
            this.formData.user_ids = this.checkList.join('|');
            this.showdepart = false;
            console.log(this.formData.user_ids, 'suredepart');
            console.log(this.formData.user_names, 'suredepart12131');
        },
        goSelect() {
            this.showdepart = true;
        },
        selectChange(item) {
            console.log(item.id, 'ss');
            this.query.department_id = item[0].id;
            // let params = window.structuredClone(this.query);
            console.log(this.query.department_id, 'rews454455');
            simpleList(this.query).then(res => {
                this.userlist = res.data.userlist;
                console.log(res, 'rews454455');
            });
            console.log(item, 'selectChange');
        },
        buildMenuTree(items, parentid = 0) {
            let tree = [];
            
            for (let i = 0; i < items.length; i++) {
                
                if (items[i].parentid === parentid) {
                    console.log(items[i].parentid,'777')
                    // 创建一个新的子树
                    let children = this.buildMenuTree(items, items[i].id);
                    // 将子树添加到当前项中
                    if (children.length) {
                        items[i].children = children;
                    }
                    // 将当前项添加到树中
                    tree.push(items[i]);
                }
            }
            return tree;
        },
        getDepartmentbmList() {
            getDepartmentList().then(res => {
                this.departmentList = res.data;
                //     let menuItems = [
                //     { id: 1, name: '菜单1', pid: null },
                //     { id: 2, name: '子菜单1', pid: 1 },
                //     { id: 3, name: '子菜单2', pid: 1 },
                //     { id: 4, name: '子菜单2-1', pid: 3 },
                //     { id: 5, name: '菜单2', pid: null },
                //     // ... 其他菜单项
                // ];
                this.departmentList.forEach(item => {
                    item.title = item.name;
                    item.expand = true;
                });
                console.log( this.departmentList, '7777');
                let menuTree = this.buildMenuTree(this.departmentList);
                this.treedata = menuTree;
                console.log(JSON.stringify(menuTree, null, 2), '5546941');
                console.log(menuTree, '5512146941');
            });
        },
        //获取小程序列表
        getMpList() {
            this.contentArr = [];
            tplMpList().then(res => {
                this.qwlist = res.data;
                res.data.forEach(item6 => {
                    let struck = JSON.parse(item6.struck);
                    let data = JSON.parse(item6.data);
                    console.log(struck, '+999');
                    console.log(data, '+999111');
                    item6.contentArr = [];
                    struck.forEach(item => {
                        for (let k in data) {
                            console.log(k, '999');
                            console.log(item.field, '9991');
                            if (item.field == k) {
                                var obj = {};
                                obj.label = item.title;
                                obj.value = data[k].value;
                                item6.contentArr.push(obj);
                            }
                        }
                    });
                });
            });
        },
        //获取企微列表
        getqwList() {
            getSubtplList().then(res => {
                this.qwlist = res.data;
            });
        },
        getData() {
            massList({ page: 1 }).then(res => {});
        },
        onCancelCreate() {
            this.showCreate = false;
        },
        onCancelCreate2() {
            this.showCreate2 = false;
        },
        onCancelCreate1() {
            this.showCreate1 = false;
        },
        addTemplate() {
            this.formData = {};
            this.cData = {};
            console.log(this.act, '555');
            if (this.actShow == '1') {
                this.showCreate = true;
            } else if(this.actShow == '2') {
                this.structList = [];
                this.showCreate1 = true;
            }else{
                this.structList1 = [];
                this.showCreate2 = true;
            }
            this.titlec = '新增';
        },
        tabsClick(name) {},
        //企微确认
        onConfirmCreate() {
            if (this.titlec == '新增') {
                this.formData.id = 0;
            }
            // this.formData.type = 1;
            let params = window.structuredClone(this.formData);
            let { title, content } = params || {};
            let vs = [
                { value: title, tip: '请输入标题' },
                { value: content, tip: '请输入内容' },
            ];
            // let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    tplCropSave(params)
                        .then(res => {
                            if (this.titlec == '新增') {
                                this.fadeAlert('新增成功');
                            } else {
                                this.fadeAlert('编辑成功');
                            }

                            this.getqwList();
                            this.showCreate = false;
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        //小程序确认
        onConfirmCreate1() {
            if (this.titlec == '新增') {
                this.formData.id = 0;
            }
            var obj = {};
            for (let k in this.cData) {
                obj[k] = {};
                obj[k].value = this.cData[k];
            }
            console.log(this.formData.trigger_type, '***');
            if (this.formData.trigger_type == '1') {
                console.log('887111');
                this.formData.page = 'pages/record/record';
            }
            this.formData.data = JSON.stringify(obj);
            this.formData.struck = JSON.stringify(this.structList);
            this.formData.type = 1;
            console.log(this.formData, '888');
            let params = window.structuredClone(this.formData);
            let { title } = params || {};
            let vs = [{ value: title, tip: '请输入标题' }];
            // let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    tplMpSave(params)
                        .then(res => {
                            if (this.titlec == '新增') {
                                this.fadeAlert('新增成功');
                            } else {
                                this.fadeAlert('编辑成功');
                            }
                            this.getMpList();
                            this.showCreate1 = false;
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        //公众号确认
         onConfirmCreate2() {
            if (this.titlec == '新增') {
                this.formData.id = 0;
            }
            var obj = {};
            for (let k in this.cData) {
                obj[k] = {};
                obj[k].value = this.cData[k];
            }
            console.log(this.formData.trigger_type, '***');
            this.formData.data = JSON.stringify(obj);
            this.formData.struck = JSON.stringify(this.structList1);
            this.formData.href = {};
            this.formData.href.url = this.formData.url;
            this.formData.href = JSON.stringify(this.formData.href);
            console.log(this.formData, '888');
            let params = window.structuredClone(this.formData);
            let { title } = params || {};
            let vs = [{ value: title, tip: '请输入标题' }];
            // let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    tplPnSave(params)
                        .then(res => {
                            if (this.titlec == '新增') {
                                this.fadeAlert('新增成功');
                            } else {
                                this.fadeAlert('编辑成功');
                            }
                            this.gettplPnList();
                            this.showCreate2 = false;
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onAdd() {
            this.showCreate = true;
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit == 'true') {
                this.showAjaxLoading();
                readAgent({ id: this.formData.id })
                    .then(res => {
                        this.formData = res.data;
                        let arr = JSON.parse(this.formData.area);
                        this.formData.areaarr = arr[0];
                        this.address = arr[1];
                        console.log(this.address, 'address');
                        this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
    },
};
</script>
<style lang="less" scoped>
.btn {
    margin: 0 20px;
    color: blue;
    cursor: pointer;
}
.stc {
    color: #c5c8ce;
}
textarea::placeholder {
    color: #c5c8ce;
}
</style>
